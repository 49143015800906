function getEducation(education)
{
    var page = parseInt($("#page").val());
    if (page == 0) page = 1;
    var education = education;
    var value = '4|'+textEdu+'|'+page;
    if(education != '')
    {
        clearAllSearchFilters();
        $('.text-education').html('Top 20<br />' + textEdu);
        var popSearchRecent = [];
        popSearchRecent = JSON.parse(localStorage.getItem('storeRecentSearch')) || popSearchRecent;
        clearAllSearchFilters();
        if(typeof popSearchRecent !== 'undefined' && popSearchRecent.length > 0)
        {
            $("#search_keyword").val(popSearchRecent.slice(-1));
            $('.joblisting_education[data-id='+education+']').prop('checked', true);
        }
        else
        {
            $('.joblisting_education[data-id='+education+']').prop('checked', true);
        }

        $.SetJobSearch();
        setCookie("MiniIconAction",value,365);
    }
    else
    {
        $('#pickeducation_modal').modal('show');
    }

}

$("#form_pick_education").on('submit', function(evt){

    var page = parseInt($("#page").val());
    if (page == 0) page = 1;
    var _this = $(this);
    var submitBtn = _this.find('.btn');
    _this.prev('.alert').hide();

    var education = $("#pick_education").val();
    if(education == '')
    {
        _this.parents('.modal-body').find('.alert').html("Please Select an education");
        _this.parents('.modal-body').find('.alert').show();
    }
    else
    {
        var newText = $('#pick_education option:selected').attr('edu');
        clearAllSearchFilters();
        $('.text-education').html('Top 20<br />' + newText);
        var value = '4|'+newText+'|'+page;
        $('#pick-alert').hide();
        $('#pickeducation_modal').modal('hide');

        var popSearchRecent = [];
        popSearchRecent = JSON.parse(localStorage.getItem('storeRecentSearch')) || popSearchRecent;
        if(typeof popSearchRecent !== 'undefined' && popSearchRecent.length > 0)
        {
            $("#search_keyword").val(popSearchRecent.slice(-1));
            $('.joblisting_education[data-id='+education+']').prop('checked', true);
        }
        else
        {
            $('.joblisting_education[data-id='+education+']').prop('checked', true);
        }
        $.SetJobSearch();
        evt.preventDefault();
        setCookie("MiniIconAction",value,365);
    }
});


$(document).ready(function() {

    $('.container-miniicon').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: false,
        nextArrow: false,
        responsive: [
            {
                breakpoint: 420,
                settings: {
                    rows: 2,
                    slidesPerRow: 1,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 795,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            }
        ]
    });
});